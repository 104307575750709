import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { ContactForm } from "../forms";

const ContactPageTemplate = ({
  title,
  subtitle,
  meta_title,
  meta_description,
}) => {
  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name="description" content={meta_description} />
      </Helmet>
      <section className="hero is-primary is-bold is-medium">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-8 is-offset-1">
                <div className="section">
                  <h1 className="title">{title}</h1>
                  <h2 className="subtitle">{subtitle}</h2>
                </div>
              </div>
              <div
                className="column has-text-right"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <p>6925 Oakland Mills Rd, #304</p>
                <p>Columbia, Maryland 21045</p>
                <a className="has-text-white" href="tel:3018549882">
                  (301) 854-9882
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container section">
          <ContactForm />
          <br />
          <br />
          <hr />
          <section>
            <div>
              <p>
                <strong>IMPORTANT:</strong> No changes or additions of any kind
                are final until you are contacted personally by a member of our
                team or your until you receive written confirmation of your
                request being completed.
              </p>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
};

export default ContactPageTemplate;
