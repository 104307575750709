import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Please enter your full name")
    .max(50, "Please enter your name")
    .required("Please enter a name"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter your email"),
  message: Yup.string().required("Please enter a message"),
});

export default validationSchema;
